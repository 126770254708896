import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderList.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import moment from "moment";

const GET_ORDERLIST = gql`
  query getTextGenerationOrderList {
    getTextGenerationOrderList {
      id
      address
      status
      error
      correctionCount
      profileName
      createdAt
      updatedAt
      validatedWithFinalText
      validationAvgChangePercentage
    }
  }
`;

const OrderList: React.FC = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ORDERLIST, {
    variables: {
      customerId: datastore.data.customerId
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    console.log("Error", error);
    //return <Error errorCode={500} />;
  }

  if (!data) {
    console.log("Error, no data");
    //return (<Error errorCode={500} />);
  }

  const getStatus = (status: string, error: String) => {
    if (error) {
      return 'Fejl';
    }
    if (status === 'CREATED') {
      return 'Afventer generering';
    }
    if (status === 'ERROR') {
      return 'Fejl';
    }
    if (status === 'DONE') {
      return  'Genereret';
    }

    return "-";
  } 

  const dateComparator = (v1: string, v2: string) => {
    if (v1 === '') {
      return 1;
    }
    if (v2 === '') {
      return -1;
    }
    const format = 'DD-MM-YYYY';
    const dateA = moment(v1, format).toDate() as Date;
    const dateB = moment(v2, format).toDate() as Date;
    return (dateB as any) - (dateA as any);
  };

  const changeComparator = (v1: string, v2: string) => {
    //console.log('changeComparator', v1, v2);
    if (v1 === '-') {
      return 1;
    }
    if (v2 === '-') {
      return -1;
    }
    const dateA = parseFloat(v1.replace('%', ''));
    const dateB = parseFloat(v2.replace('%', ''));
    //console.log(dateA, dateB);
    return (dateB as any) - (dateA as any);
  };

  const columns: GridColDef[] = [
    { field: 'address', headerName: 'Adresse', width: 270, filterable: false },
    { field: 'profile', headerName: 'Profil', flex: 1, filterable: true },
    { field: 'status', headerName: 'Status', flex: 1, filterable: true },
    { field: 'correctionCount', headerName: 'korrekturer', width: 100,  align: 'center', filterable: false },
    { field: 'validationAvgChangePercentage', headerName: 'Ændringsprocent',width: 140,  align: 'center', filterable: false,sortComparator: changeComparator },
    { field: 'createdAt', headerName: 'Oprettet', width: 140, align: 'left', sortComparator: dateComparator, filterable: false},
    { field: 'updatedAt', headerName: 'Opdateret', width: 140, align: 'left', sortComparator: dateComparator, filterable: false },
  ];

  const rows = data.getTextGenerationOrderList.map((order: any) => {
    return {
      id: order.id,
      address: order.address,
      profile: order.profileName,
      status: getStatus(order.status,order.error),
      validatedWithFinalText: order.validatedWithFinalText ? 'Ja - ' +order.validationAvgChangePercentage + '%' : 'Nej',
      validationAvgChangePercentage: order.validatedWithFinalText ? order.validationAvgChangePercentage + '%' : '-',
      createdAt: moment(order.createdAt).format("DD-MM-YYYY HH:mm"),
      updatedAt: moment(order.updatedAt).format("DD-MM-YYYY HH:mm"),
      correctionCount: order.correctionCount
    }
  });

  const handleRowClicked = (row: any) => {
    navigate('/order/' + row.id);
    //window.location.href = '/order/' + row.id;
  }

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Genereringer</div>
            <Grid container spacing={0}>
              <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                <DataGrid
                  style={{ borderWidth: '0px' }}
                  onRowClick={handleRowClicked}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [''],
                      },
                    },
                  }}
                  autoHeight={true}
                  //disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderList;

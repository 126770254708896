import React from "react";

//import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./overview.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

const GET_STATS = gql`
  query getStats($GenerationsStatsInput:GenerationsStatsInput!, $ValidationStatsInput:ValidationStatsInput!, $GenerationByShopStatsInput:GenerationByShopStatsInput!) {
    getGenerationStats(input:$GenerationsStatsInput) {
      date
      generationCount
      correctionCount
    }
    getValidationStats(input:$ValidationStatsInput) {
      date
      AvgChange
    }
    getGenerationByShopStats(input:$GenerationByShopStatsInput) {
      customer
      shopNo
      shopName
      count
    }
  }
`;



const Overview: React.FC = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_STATS, {
    variables: {
      GenerationsStatsInput: {
        fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      },
      ValidationStatsInput: {
        fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      },
      GenerationByShopStatsInput: {
        fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      }
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    console.log("Error", error);
    //return <Error errorCode={500} />;
  }

  if (!data) {
    console.log("Error, no data");
    //return (<Error errorCode={500} />);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  /******************************************** */
  //VALIDATION CHART
  /******************************************** */
  const HorizontalLinePlugin = {
    id: "horizontalLine",
    afterDraw: (chart: any) => {
      const { ctx, chartArea: { left, right }, scales: { y } } = chart;
      const yValue = y.getPixelForValue(14.52); // Change this value for your desired Y position
  
      ctx.save();

      // Draw the horizontal line
      ctx.beginPath();
      ctx.moveTo(left, yValue);
      ctx.lineTo(right, yValue);
      ctx.strokeStyle = "red"; // Change line color
      ctx.lineWidth = 1;
      ctx.stroke();
      

      // Add label text
    ctx.fillStyle = "red";
    ctx.font = "14px Arial";
    ctx.fillText("Gns. for tekstforfattere - 14,52% (januar 2025)", left , yValue - 7); // Adjust position if needed

    ctx.restore();
    }
  };

  const ValidationChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          usePointStyle: true //for style circle
        }
      },
      title: {
        display: false,
        text: 'Gns. ænding i procent i forhold til endelig tekst',
      },
    },
  };

  const ValidationChartLabels = data.getValidationStats.map((stats: any) => moment(stats.date).format('DD-MM-YYYY'));
  const ValidationChartData = {
    labels: ValidationChartLabels,
    datasets: [
      {
        label: 'Gns. ænding i procent',
        data: data.getValidationStats.map((stats: any) => stats.AvgChange),
        borderColor: 'rgb(19, 230, 0)',
        backgroundColor: 'rgba(19, 230, 0, 0.5)',
      },
    ],
  };

  /******************************************** */
  //VALIDATION CHART - END
  /******************************************** */


  const GenerationChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          usePointStyle: true //for style circle
        }
      },
      title: {
        display: false,
        text: 'Oprettelser og korrektioner',
      },
    },
  };


  const GenerationChartLabels = data.getGenerationStats.map((stats: any) => moment(stats.date).format('DD-MM-YYYY')).reverse();
  const GenerationChartData = {
    labels: GenerationChartLabels,
    datasets: [
      {
        label: 'Oprettelser',
        data: data.getGenerationStats.map((stats: any) => stats.generationCount).reverse(),
        borderColor: 'rgb(19, 230, 0)',
        backgroundColor: 'rgba(19, 230, 0, 0.5)',
        fill: {
          target: 'origin',
          above: 'rgba(19, 230, 0, 0.5)',   // Area will be red above the origin
        }
      },
      {
        label: 'korrektioner',
        data: data.getGenerationStats.map((stats: any) => stats.correctionCount).reverse(),
        borderColor: 'rgb(99, 99, 99)',
        backgroundColor: 'rgb(99, 99, 99, 0.5)',
        fill: {
          target: 'origin',
          above: 'rgba(99, 99, 99, 0.5)',   // Area will be red above the origin
        }
      },
    ],
  };

  //Customer data

  //group by customer by summing up the count
  let customerMap = new Map();
  data.getGenerationByShopStats.forEach((order: any) => {
    if (customerMap.has(order.customer)) {
      let count = customerMap.get(order.customer);
      customerMap.set(order.customer, count + order.count);
    } else {
      customerMap.set(order.customer, order.count);
    }
  });

  const customerColumns: GridColDef[] = [
    { field: 'customer', headerName: 'Kunde', flex: 1, filterable: false },
    { field: 'count', headerName: 'Antal', width: 100, align: 'center', filterable: false },
  ];

  //Sort customerMap by count
  customerMap = new Map([...customerMap.entries()].sort((a, b) => b[1] - a[1]));

  const customerRows = Array.from(customerMap).map((entry: any) => {
    return {
      id: entry[0] + '_' + entry[1],
      customer: entry[0],
      count: entry[1]
    }
  });


  const columns: GridColDef[] = [
    { field: 'customer', headerName: 'Kunde', flex: 1, filterable: false },
    { field: 'shopName', headerName: 'Butiksnavn', flex: 1, filterable: false },
    { field: 'shopNo', headerName: 'Butiks nr.', flex: 1, filterable: false },
    { field: 'count', headerName: 'Antal', width: 100, align: 'center', filterable: false },
  ];

  const rows = data.getGenerationByShopStats.map((order: any) => {
    return {
      id: order.customer + '_' + order.shopNo + '_' + order.count,
      customer: order.customer,
      shopNo: order.shopNo,
      shopName: order.shopName,
      count: order.count
    }
  });

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.Overview} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Overblik</div>
            <h2>Oprettelser og korrektioner</h2>
            <Grid container >
              <Grid xs={12} style={{ height: '300px', width: '100%', borderWidth: '0px' }}>
                <div style={{ height: '300px', width: '100%', borderWidth: '0px' }}>
                  <Line options={GenerationChartOptions} data={GenerationChartData} />
                </div>
              </Grid>
            </Grid>
            <h2>Gns. ænding i procent i forhold til endelig tekst</h2>
            <Grid container height={'300px'} >
              <Grid xs={12} style={{ height: '300px', width: '100%', borderWidth: '0px' }}>
                
                <div style={{ height: '300px', width: '100%', borderWidth: '0px' }}>
                  <Bar options={ValidationChartOptions} data={ValidationChartData} plugins={[HorizontalLinePlugin]} />
                </div>
              </Grid>
            </Grid>
            <h2>Oprettelser fordelt på kunder</h2>
            <Grid container >
              
              <DataGrid
                style={{ borderWidth: '0px' }}
                rows={customerRows}
                columns={customerColumns}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [''],
                    },
                  },
                }}
                autoHeight={true}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                sx={{
                  // disable cell selection style
                  '.MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  // pointer cursor on ALL rows
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                  }
                }}
              />
             
            </Grid>
            <h2>Oprettelser fordelt på butikker</h2>
            <Grid container>
              <DataGrid
                style={{ borderWidth: '0px' }}
                rows={rows}
                columns={columns}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [''],
                    },
                  },
                }}
                autoHeight={true}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                sx={{
                  // disable cell selection style
                  '.MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  // pointer cursor on ALL rows
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                  }
                }}
              />
              
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;

import React, { FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useQuery, gql, useMutation } from "@apollo/client";
import { FormControl, TextField } from "@mui/material";
import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from "moment";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import './LogItem.css'

export interface LogItemProps {
    logItem: any;
}

const LogItem: FC<LogItemProps> = (props) => {

    const [showDetails, setShowDetails] = React.useState(false);

    const handleTroggleDetails = () => {
        setShowDetails(!showDetails);
    }

    function formatLogInfo(logInfo: string) {
        try {
            // Attempt to parse the info field
            const parsedInfo = JSON.parse(logInfo);
            
            console.log("JSON!!!", parsedInfo);
            // Pretty-print the JSON

            let formattedJSON = JSON.stringify(parsedInfo, null, 2);
            //replace any \n in the string with <br>
            formattedJSON = formattedJSON.replace(/\\n/g, '<br/>');

            //.replace(/</g, '&lt;').replace(/>/g, '&gt;')
            return makeLinksClickable(`<pre>${formattedJSON}</pre>`);
            //return JSON.stringify(parsedInfo, null, 2);
        } catch (error) {
            // If parsing fails, return the original info
            return logInfo;
        }
    }

    function makeLinksClickable(text: string) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }

    return (
        <>
            <div className="mainLogItem" onClick={handleTroggleDetails}>
                {props.logItem.logType === "ERROR" && (
                    <div className="logItemRed"></div>
                )}
                {props.logItem.logType === "WARNING" && (
                    <div className="logItemYellow"></div>
                )}
                {props.logItem.logType === "INFO" && (
                    <div className="logItemGreen"></div>
                )}
                {showDetails ? (
                    <div className="logItemIcon"><FaAngleDown /></div>
                ) : (
                    <div className="logItemIcon"><FaAngleRight /></div>
                )}<div className="logItemDate">
                    {moment(props.logItem.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                </div>
                <div className="logItemLogType">
                    {props.logItem.logType}
                </div>
                <div className="logItemActivity">
                    {props.logItem.activity}
                </div>


            </div>
            <div className="logItemDetails" style={{ display: showDetails ? "block" : "none" }}>
                {props.logItem.logType === "ERROR" && (
                    <div className="logItemDetailsRed"></div>
                )}
                {props.logItem.logType === "WARNING" && (
                    <div className="logItemDetailsYellow"></div>
                )}
                {props.logItem.logType === "INFO" && (
                    <div className="logItemDetailsGreen"></div>
                )}
                <div className="logItemDetailsItem">
                    <div className="logItemDetailsItemLabel">Activity:</div>
                    <div className="logItemDetailsItemData" dangerouslySetInnerHTML={{ __html: makeLinksClickable(props.logItem.activity) }}></div>
                </div>
                <div className="logItemDetailsItem">
                    <div className="logItemDetailsItemLabel">Info:</div>
                    <div className="logItemDetailsItemData" dangerouslySetInnerHTML={{ __html: formatLogInfo(props.logItem.info) }}></div>
                </div>
            </div>
        </>
    );
}

export default LogItem;
//import Mindworking from "./utils/mindworking";

import { userInfo } from "os";

export const datastore = {
  data: {
    customerId: "" as string,
    name: "" as string,
    token: "" as string,
    userType: "" as string,
    aiText: {
      websiteUrl: "" as string,
      highlightText: "" as string,
      address: "" as string,
      imageUrls: [] as string[]
    }
  },
};
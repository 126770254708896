import React from "react";
//import Error from "../error/error";
//import { FaRegPlayCircle } from "react-icons/fa";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderInfo.css";
import { useQuery, gql } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent, CardHeader } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import moment from "moment";
import { datastore } from "../../datastore";
import LogItem from "../../components/LogItem/LogItem";

/*
const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;
*/

const GET_ORDER = gql`
  query getTextGenerationOrder($id: ID!) {
    getTextGenerationOrder(id: $id) {
      id
      address
      headline
      normaltext
      someText
      areaText
      images {
        id
        url
      }
      highlightText
      createdAt
      caseNo
      uniqueLogKey
      validatedWithFinalText
      validationLargestChanges
      validationAvgChangePercentage
      
      logs {
        id
        user {
          id
          name
        }
        activity
        info
        logType
        createdAt
      }
      profile {
        id
        name
      }
      textCorrections {
        id
        error
        status
        correctionText
        texts {
          id
          text
          relatedImages
          validatedWithFinalText
          validationLargestChanges
          validationChangePercentage
          validationFinalText
          section {
            id
            name
            externalRef
            sorting
            textType
            createdAt
            textLength
          }
        }
      }
      texts {
        id
        text
        relatedImages
        validatedWithFinalText
          validationLargestChanges
          validationChangePercentage
          validationFinalText
        section {
          id
          name
          externalRef
          sorting
          textType
          createdAt
          textLength
        }
      } 
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const OrderInfo: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  //const [openCopyToClipboardInfo, setOpenCopyToClipboardInfo] = React.useState(false);
  //const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  //const [openNoteSavedInfo, setOpenNoteSavedInfo] = React.useState(false);
  //const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  //const [currentNoteText, setCurrentNoteText] = React.useState("");
  const [tabSelected, setTabSelected] = React.useState(0);
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState(0);
  const [showValidationInfo, setShowValidationInfo] = React.useState(false);
  const [showValidationInfoText, setshowValidationInfoText] = React.useState(-1);
  //const [updateOrder] = useMutation(UPDATE_ORDER);
  const thumbnailsRef = React.useRef(null);

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  }


  /*
    const addLinebreaks = (text: String) => {
      return text.replaceAll("\\n", "\n");
    };
  */
  //map media to slides
  const slides = data.getTextGenerationOrder.images.map((image: any) => {

    return { src: image.url.split("?")[0] + "?width=800", SrcSet: [{ src: image.url.split("?")[0] + "?width=200" }], type: "image" };

  });

  //sort text by section

  let onsortedTexts = [...data.getTextGenerationOrder.texts];

  //remove text with no section
  onsortedTexts = onsortedTexts.filter((text: any) => {
    return text.section !== null;
  });

  const texts = onsortedTexts.sort((a: any, b: any) => {
    if (b.section.sorting > 0) {
      if (a.section.sorting < b.section.sorting) {
        return -1;
      }
      if (a.section.sorting > b.section.sorting) {
        return 1;
      }
      return 0;
    } else {
      if (a.section.createdAt < b.section.createdAt) {
        return -1;
      }
      if (a.section.createdAt > b.section.createdAt) {
        return 1;
      }
      return 0;
    }
  });

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getTextLength = (text: any) => {
    if (text.text.length > text.section.textLength) {
      return <span className="textLengthTooLong">Antal tegn: {text.text.length + " (Maks.:" + text.section.textLength})</span>
    }
    return <span className="textLengthOk">Antal tegn: {text.text.length + " (Maks.:" + text.section.textLength})</span>
  }

  const showHideValidationTextInfo = (index: number) => {
    if (showValidationInfoText === index) {
      setshowValidationInfoText(-1);
    } else {
      setshowValidationInfoText(index);
    }
  }

  const getTextValidation = (text: any, index: number) => {

    if (text.validatedWithFinalText) {
      if (text.validationChangePercentage > 40) {
        return <span className="textValidationNotOk" onClick={() => { showHideValidationTextInfo(index) }}><span>Ændret: </span><span className="textValidationBad">{text.validationChangePercentage.toFixed(2)} %<br />(klik for mere info)</span></span>
      }
      if (text.validationChangePercentage > 10) {
        return <span className="textValidationWarning" onClick={() => { showHideValidationTextInfo(index) }}><span>Ændret: </span><span className="textValidationMedium">{text.validationChangePercentage.toFixed(2)} %<br />(klik for mere info)</span></span>
      }
      if (text.validationChangePercentage > 0) {
        return <span className="textValidationOk" onClick={() => { showHideValidationTextInfo(index) }}><span>Ændret: </span><span className="textValidationGood">{text.validationChangePercentage.toFixed(2)} %<br />(klik for mere info)</span></span>
      }
      if (text.validationChangePercentage === 0) {
        return <span className="textValidationOk"><span>Ændret: </span><span className="textValidationGood">0 %</span></span>
      }
    }

    return <span></span>

  }

  const addressLink = 'https://maps.google.com/?q=' + encodeURIComponent(data.getTextGenerationOrder.address)

  const getBackgroundColor = (validationAvgChangePercentage: number) => {
    if (validationAvgChangePercentage > 40) {
      return 'rgb(255 152 152)';
    }
    if (validationAvgChangePercentage > 20) {
      return 'rgb(255 182 84)';
    }
    return 'rgb(166 255 173)';
  }

  function convertMarkdownToHTML(markdown: string): string {

    console.log(markdown);

    // Break text into lines
    const lines = markdown.split('\n');
    let html = '';

    for (let line of lines) {
      line = line.trim();

      // Headings and subheadings
      if (line.startsWith('### ')) {
        html += `<h3>${line.slice(4)}</h3>`;
      } else if (line.startsWith('#### ')) {
        html += `<h4>${line.slice(5)}</h4>`;
      } else if (line.startsWith('- ')) {
        html += `<li>${line.slice(2)}</li>`;
      } else {
        html += `<p>${line}</p>`;
      }
    }

    // Wrap lists properly
    html = html.replace(/(<li>.*?<\/li>)+/g, match => `<ul>${match}</ul>`);

    return html;
  }

  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Genereret tekst</div>
            <Grid container spacing={2}>

              {data.getTextGenerationOrder.validatedWithFinalText && (
                <Grid xs={12}>
                  <Paper elevation={3} style={{ height: '100%' }} >
                    <Card style={{ height: '100%', backgroundColor: getBackgroundColor(data.getTextGenerationOrder.validationAvgChangePercentage) }}>
                      <CardHeader title={'Validering'} />
                      <CardContent>
                        <div>
                          <div className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>

                            <div className="itemData">Teksterne er sammelignet med de endelige tekster benyttet, mægleren har ændret {data.getTextGenerationOrder.validationAvgChangePercentage} % af den leveret tekst.</div>
                            {showValidationInfo ? (
                              <div style={{ cursor: 'pointer' }} onClick={() => { setShowValidationInfo(false) }}><b>Se mindre.</b></div>
                            ) : (
                              <div style={{ cursor: 'pointer' }} onClick={() => { setShowValidationInfo(true) }}><b>Klik her for mere info</b></div>
                            )}

                            {showValidationInfo && (
                              <div dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(data.getTextGenerationOrder.validationLargestChanges) }}></div>
                            )}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}

              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Info'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Sags nr.:</div>
                          <div className="itemData">{data.getTextGenerationOrder.caseNo}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Addresse:</div>
                          <div className="itemData">{data.getTextGenerationOrder.address}
                            <a href={addressLink} target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Interface / External_Link">
                                  <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Fremhævet:</div>
                          <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.highlightText.replaceAll('\n', '<br/>') }}></div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Billeder:</div>
                          <div className="itemData itemImage" >{data.getTextGenerationOrder.images.length}

                            <div onClick={() => { setSelectedImage(0); setOpenImageViewer(true) }} style={{ cursor: 'pointer' }}>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Interface / External_Link">
                                  <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Genereringsprofil:</div>
                          <div className="itemData itemImage">{data.getTextGenerationOrder.profile.name}
                            <div onClick={() => { navigate('/profile/' + data.getTextGenerationOrder.profile.id); }} style={{ cursor: 'pointer' }}>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Interface / External_Link">
                                  <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </div>

                          </div>
                        </div>



                        <div className="item">
                          <div className="itemLabel">Log id:</div>
                          <div className="itemData">{data.getTextGenerationOrder.uniqueLogKey}</div>
                        </div>



                        <div className="item">
                          <div className="itemLabel">Genereret d.:</div>
                          <div className="itemData">{moment(data.getTextGenerationOrder.createdAt).format("DD-MM-YYYY")} {String(new Date(data.getTextGenerationOrder.createdAt).getHours()).padStart(2, '0') + ":" + String(new Date(data.getTextGenerationOrder.createdAt).getMinutes()).padStart(2, '0')}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={12}>
                <Paper elevation={3} >
                  <Card>
                    <CardHeader title={'Tekster'} />
                    <CardContent>
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={tabSelected} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Profil tekster" {...a11yProps(0)} />
                            <Tab label="Rå tekster" {...a11yProps(1)} />
                            {data.getTextGenerationOrder.textCorrections && data.getTextGenerationOrder.textCorrections.length > 0 && (
                              data.getTextGenerationOrder.textCorrections.map((textCorrection: any, index: number) => {
                                return (
                                  <Tab key={index + 2} label={"Korrektur " + (index + 1)} {...a11yProps(index + 2)} />
                                )
                              })
                            )}

                            {localStorage.getItem('userType') === 'SUPERADMIN' && (
                              <Tab label="Log" {...a11yProps(data.getTextGenerationOrder.textCorrections.length + 3)} />
                            )}
                          </Tabs>
                        </Box>

                        <CustomTabPanel value={tabSelected} index={0}>
                          <div>
                            {texts.map((text: any, index: number) => {
                              if (text.relatedImages.length > 0 && text.section.textType === 'IMAGES_WITH_TAGS_TEXT') {
                                return (
                                  <>
                                    <div key={index} className="item">
                                      <div className="itemLabel">{text.section.name}<br />{getTextLength(text)}<br />{getTextValidation(text, index)}</div>
                                      <div className="itemData" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n', '<br/>') }}></div>
                                    </div>
                                    {showValidationInfoText === index && (
                                      <div key={'validation_' + index} className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                        <div style={{ marginLeft: '22%' }} dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML('#### Største ændringer:\n\n' + text.validationLargestChanges) }}></div>
                                      </div>
                                    )}
                                    <div key={index + '_images'} className="item">
                                      <div className="itemLabel"></div>
                                      <div className="itemImages">
                                        {text.relatedImages.map((image: any, index: number) => {
                                          return (
                                            <div key={index} className="itemImage">
                                              <img src={image.split("?")[0] + "?width=200"} alt="" />
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>

                                  </>
                                )
                              } else {
                                return (
                                  <>
                                    <div key={index} className="item">
                                      <div className="itemLabel">{text.section.name}<br />{getTextLength(text)}<br />{getTextValidation(text, index)}</div>
                                      <div className="itemData" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n', '<br/>') }}></div>
                                    </div>
                                    {showValidationInfoText === index && (
                                      <>
                                        <div key={'validation_' + index} className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                          <div style={{ marginLeft: '22%' }} dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML('#### Største ændringer:\n\n' + text.validationLargestChanges) }}></div>
                                        </div>
                                        {text.validationFinalText !== '' && (
                                          <div key={'validation_' + index} className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                            <div style={{ marginLeft: '22%' }}><h4>Endelig tekst benyttet af mægler:</h4></div>
                                            <div style={{ marginLeft: '22%', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: text.validationFinalText.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )
                              }

                            })
                            }
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabSelected} index={1}>
                          <div>
                            <div className="item">
                              <div className="itemLabel">Overskrift:<br /><span className="rawTextLength">Antal tegn: {data.getTextGenerationOrder.headline.length}</span></div>
                              <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.headline.replaceAll('\n', '<br/>') }}></div>
                            </div>
                            <div className="item">
                              <div className="itemLabel">Normal tekst:<br /><span className="rawTextLength">Antal tegn: {data.getTextGenerationOrder.normaltext.length}</span></div>
                              <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.normaltext.replaceAll('\n', '<br/>') }}></div>
                            </div>
                            {data.getTextGenerationOrder.areaText.length > 0 && (
                              <div className="item">
                                <div className="itemLabel">Område tekst:<br /><span className="rawTextLength">Antal tegn: {data.getTextGenerationOrder.areaText.length}</span></div>
                                <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.areaText.replaceAll('\n', '<br/>') }}></div>
                              </div>
                            )}
                            <div className="item">
                              <div className="itemLabel">SoMe tekst:<br /><span className="rawTextLength">Antal tegn:  {data.getTextGenerationOrder.someText.length}</span></div>
                              <div className="itemData" dangerouslySetInnerHTML={{ __html: data.getTextGenerationOrder.someText.replaceAll('\n', '<br/>') }}></div>
                            </div>
                          </div>
                        </CustomTabPanel>
                        {data.getTextGenerationOrder.textCorrections && data.getTextGenerationOrder.textCorrections.length > 0 && (
                          data.getTextGenerationOrder.textCorrections.map((textCorrection: any, index: number) => {
                            return (
                              <CustomTabPanel key={index} value={tabSelected} index={index + 2}>
                                <div>

                                  <div className="correctionItem">
                                    <div className="itemLabel">Korrektur/Rettelse:</div>
                                    <div className="itemData" dangerouslySetInnerHTML={{ __html: textCorrection.correctionText.replaceAll('\n', '<br/>') }}></div>
                                  </div>
                                  {textCorrection.texts.map((text: any, index: number) => {
                                    if (text.relatedImages.length > 0 && text.section.textType === 'IMAGES_WITH_TAGS_TEXT') {
                                      return (
                                        <>
                                          <div key={index} className="item">
                                            <div className="itemLabel">{text.section.name}<br />{getTextLength(text)}<br />{getTextValidation(text, index)}</div>
                                            <div className="itemData" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                          {showValidationInfoText === index && (
                                            <div key={'validation_' + index} className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                              <div style={{ marginLeft: '22%' }} dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML('#### Største ændringer:\n\n' + text.validationLargestChanges) }}></div>
                                            </div>
                                          )}
                                          <div key={index + '_images'} className="item">
                                            <div className="itemLabel"></div>
                                            <div className="itemImages">
                                              {text.relatedImages.map((image: any, index: number) => {
                                                return (
                                                  <div key={index} className="itemImage">
                                                    <img src={image.split("?")[0] + "?width=200"} alt="" />
                                                  </div>
                                                )
                                              })}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    } else {
                                      return (
                                        <>
                                          <div key={index} className="item">
                                            <div className="itemLabel">{text.section.name}<br />{getTextLength(text)}<br />{getTextValidation(text, index)}</div>
                                            <div className="itemData" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n', '<br/>') }}></div>

                                          </div>
                                          {showValidationInfoText === index && (
                                            <>
                                              <div key={'validation_' + index} className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                                <div style={{ marginLeft: '22%' }} dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML('#### Største ændringer:\n\n' + text.validationLargestChanges) }}></div>
                                              </div>
                                              {text.validationFinalText !== '' && (
                                                <div key={'validation_' + index} className="item" style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
                                                  <div style={{ marginLeft: '22%' }}><h4>Endelig tekst benyttet af mægler:</h4></div>
                                                  <div style={{ marginLeft: '22%', fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: text.validationFinalText.replaceAll('\n', '<br/>') }}></div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )
                                    }
                                  })}
                                </div>
                              </CustomTabPanel>
                            )
                          })
                        )}
                        {localStorage.getItem('userType') === 'SUPERADMIN' && (

                          <CustomTabPanel key={data.getTextGenerationOrder.textCorrections.length + 3} value={tabSelected} index={data.getTextGenerationOrder.textCorrections.length + 2}>
                            <div>

                              {data.getTextGenerationOrder.logs.map((log: any, index: number) => {
                                return (
                                  <LogItem key={index} logItem={log} />
                                )
                              })}
                            </div>
                          </CustomTabPanel>


                        )}
                      </>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>



              {/* Images and videos */}
              {data.getTextGenerationOrder.images.length > 0 && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={'Media'} />
                      <CardContent>
                        <div>
                          <div className="mediaContainer">
                            {data.getTextGenerationOrder.images.map((image: any, index: number) => {
                              return (
                                <div key={index} className="mediaItem" onClick={() => { setSelectedImage(index); setOpenImageViewer(true) }}>
                                  <img src={image.url.split("?")[0] + "?width=200"} alt="" />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Lightbox
        styles={{ thumbnailsContainer: { backgroundColor: "#353535" } }}
        plugins={[Thumbnails, Counter, Video]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        thumbnails={{ ref: thumbnailsRef }}
        open={openImageViewer}
        close={() => setOpenImageViewer(false)}
        slides={slides}
        index={SelectedImage}
      />
    </div>
  );
};

export default OrderInfo;
